.detail_surprise_container {
  padding: 10px;
}

.d-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dashboardoverview_container_fluid {
  padding: 30px 5px;
}

.overview_edit_btn {
  color: var(--colorused);
  padding: 0px 15px;
  font-weight: 600;
  cursor: pointer;
  margin: 0px;
}

.overview_img_container {
  width: 100%;
  height: 350px;
  background-color: #ffffff;
}

.overview_img_container img {
  width: 100%;
  height: 100%;
  object-fit: contain;

}

.overview_details_h {
  margin: 0px;
  font-family: Tenon, "Open Sans", Roboto, Arial, sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.005em;
  white-space: initial;
  overflow: initial;
  text-overflow: initial;
  text-transform: initial;
  color: #5f6d6d;
}

.overview_details_p {
  margin: 0px;
  font-family: Tenon, "Open Sans", Roboto, Arial, sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.005em;
  white-space: initial;
  overflow: initial;
  text-overflow: initial;
  text-transform: initial;
}

.overview_details_inside_contain {
  padding: 15px 0px;
  width: 100%;
}

.m-b-10 {
  margin-bottom: 10px;
}

.logo_EditButton {
  width: 40px;
  height: 40px;
  background: var(--colorused);
  font-size: 1.71429rem;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  border-radius: 50%;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  padding: 8px;
  text-align: center;
  color: white;
  position: absolute;
  right: 62px;
  border: none;
}


.poupdiv {
  position: absolute;
  right: 32px;
  background: white;
  width: 250px;
  height: cover;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 10px;
  opacity: 1;
  text-align: center;
  border-radius: 2%;
  z-index: -1;
  transform-origin: 214px 70.9045px;
}








/* / =============================================================================================== / */

.TabList {
  position: relative;
  display: flex;
  list-style-type: none;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  width: 100%;
}

/* @media (max-width: 765px) and (min-width: 320px) {
  .TabList {
    overflow-x: scroll !important; 
  }
} */

@media (max-width: 765px) and (min-width: 320px) {
  .TabList {
    overflow-x: scroll !important;
  }
  
  .TabList::-webkit-scrollbar {
    display: none;
  }
}

.TabList::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #ccc;
  z-index: -1;
}

.Tab {
  position: relative;
  padding: 10px 15px;
  margin-right: 10px;
  cursor: pointer;
  border-bottom: 5px solid transparent;
  outline: none;
  display: flex;
  align-items: center;
  gap: 5px;
}

.Tab:focus,
.Tab:hover {
  border-bottom-color: transparent;
}

.Tab[aria-selected="true"] {
  border-bottom-color: var(--colorused);
  color: var(--colorused);
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 5px;
}

.TabPanel {
  padding: 20px;
  border: 1px solid #ccc;
  border-top: none;
}

.size_container_overview {
  display: flex !important;
  justify-content: space-between !important;
  padding: 10px !important;
  border: 1px solid gray !important;
  border-radius: 6px !important;
  margin: 10px 0px;
}


/* / ======================================setting====================================================== / */

.form-switch .form-check-input {
  width: 4em !important;
  height: 2em !important;
}

.bttnnn {
  border: none;
  color: var(--colorused);
  cursor: pointer;
}

.bttnnn1 {
  border-radius: 23px;
  border: none;
  background-color: var(--colorused);
  color: white;
  padding: 9px 15px;
  cursor: pointer;
}

.form-check-input:checked {
  background-color: var(--colorused);
  border-color: var(--colorused);
}


.surprise_bag_details_heading {
  display: flex;
  padding: 15px 20px;
  background-color: #ffffff;
  border-radius: 15px;
  gap: 5px;
  align-items: center;
}

/* / calender / */

.slideshow-calendar {
  text-align: center;
  margin: 20px;
}

.calendar-container {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
}

.slide {
  min-width: 100%;
  padding: 25px;
  border: none;
  background-color: #f9f9f9;
  scroll-snap-align: start;
  flex: 0 0 auto;
  margin-right: 10px;
}

.calendar-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.calendar-day {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px;
  width: 85px;
  border: 1px solid #ccc;
  background-color: #fff;
}

.controls {
  margin-top: 10px;
}

.controls button {
  margin: 0 5px;
  padding: 8px 12px;
  font-size: 14px;
  cursor: pointer;
}

/* / new / */
.calendar {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border: none;
}

.month-controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.days-of-week {
  display: flex;
  margin-bottom: 10px;
}

.day-of-week {
  width: calc(100% / 7);
  text-align: center;
  padding: 5px;
  border: none;
  font-size: 13px;
}

.days {
  display: grid;
  grid-template-columns: repeat(7, 1fr);

}

.day {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  border: 1px solid #ccc;
}

.day.empty {
  background-color: #f0f0f0;

}

.day-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
}

.day-button:hover {
  background-color: #e0e0e0;

}

.calender_container {
  display: flex;
  justify-content: center;
}

.calender_container_fluid {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  width: 65%;
  min-width: 400px;
  padding: 10px 20px;
  background-color: #ffffff;
  border-radius: 15px;
  width: 100%;
  margin-top: 20px;
}

.change_profile {
  padding: 10px;
  text-align: justify;
}

.change_profile:hover {
  background-color: #e0e0e0;
}

.logo_img {
  /* width: 100px !important;
  height: 100px !important;
  border-radius: 50% !important; */
  position: relative !important;
  left: 41%;
  top: -63px;
  border: 2px solid #ffffff;

}