.loading{
    height: 80vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.loading_conponent_text{
    color: green;
    font-size: 18px;
}