.form-switch .form-check-input {
    width: 4em !important;
    height: 2em !important;
}

.bttnnn {
    border: none;
    color: var(--colorused);
}

.bttnnn1 {
    border-radius: 23px;
    border: none;
    background-color: var(--colorused);
    color: white;
    padding: 9px 15px;
}

.form-check-input:checked {
    background-color: var(--colorused);
    border-color: var(--colorused);
}

.instruction_value_p {
    background: #fbf7f4;
    padding: 15px;
}
.instruction_value_switch {
    padding: 15px;
    display: flex;
    align-items: center;
    gap: 10px;
}
.instructions_details_h{
    margin: 0px;
    font-family: Tenon, "Open Sans", Roboto, Arial, sans-serif;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.017em;
    white-space: initial;
    overflow: initial;
    text-overflow: initial;
    text-transform: initial;
    display: flex;
    align-items: stretch;
    gap: 5px;
  }
  .instruction_details_p{
    margin: 0px 0px 16px;
    font-family: Tenon, "Open Sans", Roboto, Arial, sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.005em;
    white-space: initial;
    overflow: initial;
    text-overflow: initial;
    text-transform: initial;
  }

  .m-t-10{
margin-top: 10px;
  }

  .buffet_switch{
    padding: 10px;
  }
  .autocomplete_instruction{
    padding: 12px;
  }