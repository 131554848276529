.sidebar_heading_of_list {
  margin: 20px 0px 0px 16px;
  font-family: Tenon, "Open Sans", Roboto, Arial, sans-serif;
  font-weight: 600;
  font-size: 13.33px;
  line-height: 16px;
  letter-spacing: 0.005em;
  white-space: initial;
  overflow: initial;
  text-overflow: initial;
  text-transform: initial;
  color: rgb(95, 109, 109);
}

.logo_pickntreat {
  max-height: 100px;
  max-width: 120px;
  /* height: 100px; */
  object-fit: contain;
}

.logo_pickntreat_withtext {
  max-height: 100px;
  max-width: 150px;
  /* height: 100px; */
  object-fit: contain;
}
.logo_text_container{
text-align: center;
}
.logo_text_container img{
  height: 40px;
  object-fit: contain;
}

.drawer_header {
  display: flex !important;
  justify-content: center !important;
  margin: 30px;
  padding: 4px 0px !important;
  flex-direction: column;
  gap: 6px;
}

.sidebar_ul_logout {
  margin: 20px 0px;
}

.sidebar_list {
  margin: 5px 7px;
  width: 265px !important;
  border-radius: 6px;
}

.sidebar_list:hover {
  background-color: #f4f6f6 !important;
}

.list_button_hover:hover {
  background-color: #f4f6f6 !important;
}

.active_class {
  background-color: #f4f6f6;
}

.swal-button--danger {
  background-color: #193d40 !important;
}

.swal-button--danger:hover {
  background-color: #193d40 !important;
}

.vendorname_sidebar_container {
  border: 1px solid #e0e0e0;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
  margin: 7px;
  width: 95%;
  border-radius: 6px;
  box-sizing: border-box;
}


.vender_name_text_sidebar {
  margin: 0px;
  font-family: Tenon, "Open Sans", Roboto, Arial, sans-serif;
  font-weight: 400;
  font-size: 13.3px;
  line-height: 16px;
  letter-spacing: 0.002em;
  text-transform: none;
  display: block; 
  word-wrap: break-word; 
  overflow-wrap: break-word;
  white-space: normal; 
}
.focus_button {
  display: none !important;
}
.appbar_container {
  display: none !important;
}

@media screen and (max-width: 820px) {
  .drawer_sidebar {
    display: none !important;
  }
  .appbar_container {
    display: flex !important;
  }
  .focus_button {
    display: block !important;
  }

  .appbar_container {
    width: 100% !important;
  }
}

/* .drawer_mobile_responsive {
  position: absolute;
  background-color: #fff;
  min-height: 100vh;
  z-index: 99999;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
} */

.drawer_mobile_responsive {
  position: absolute;
  background-color: #fff;
  min-height: 96vh;
  z-index: 99999;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  animation-name: slideInLeft;
  animation-duration: 0.8s;
  animation-timing-function: ease-in-out;
  top: 0;
}

@keyframes slideInLeft {
  from {
    opacity: 0;
    transform: translateX(-100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
.menuText {
  font-size: 12px;
  margin: 0px;
  line-height: 2px;
  color: #000;
}
.main_layout_div {
  padding: 25px 0px;
  background-color: var(--mainbackground);
  display: flex;
  gap: 7px;
  overflow: auto;
  box-sizing: border-box;
  height: 100vh;
}
.drawer_sidebar .MuiDrawer-paper {
  margin: 25px;
  box-sizing: border-box;
  border-radius: 10px;
  height: 93%;
  /* overflow: visible; */
  overflow-y: scroll;
padding: 15px 0px;
  border-radius: 20px;
}
.drawer_sidebar .MuiDrawer-paper::-webkit-scrollbar {
  display: none; /* Hides scrollbar in WebKit browsers (Chrome, Safari, etc.) */
}
.main_layout_div::-webkit-scrollbar {
  display: none; /* Hides scrollbar in WebKit browsers (Chrome, Safari, etc.) */
}
.scrollbar_hide::-webkit-scrollbar {
  display: none; /* Hides scrollbar in WebKit browsers (Chrome, Safari, etc.) */
}
.outlet_container {
  border-radius: 20px;
  background-color: #ffffff;
  border: none;
  padding: 20px 0px !important;
  margin: 0px 15px;
}

@media screen and (max-width: 820px) {
  .outlet_container {
    margin: 25px 0px !important;
  }
}
.mobile_notification_tab{
  position: fixed;
  top: 0;
  right: -16px;
  background: #ffffff;
  max-width: 90%;
}

