.excle_btn{
    background-color: var(--colorused);
    color: #fff;
    text-align: center;
    height: 55px;
    border-radius: 6px;
    border: none;
    max-width: 200px;
    padding: 0px 20px;
    cursor: pointer;
    white-space: nowrap !important;
}

.page_heading_div_excel{
    background-color: #ffffff;
    border-radius: 10px;
    margin: 20px 0px;
    padding: 15px 10px;

}