.inputfield_container_div{
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    box-sizing: border-box;
}
.inputfield_container_div_inside{                                         
    width: 48%;
    min-width: 250px;
}
.inputfield_container_div_inside label{
    width: 100%;
    min-width: 250px;
}

.document_container {
    min-height: 100vh;
    width: 100%;
    padding: 30px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.dropzone_field{
    padding: 2px;
    width: 100%;
    height: 55px;
    border-radius: 4px;
    border: 1px solid #c9c9c9;
    background: #fff;
  }

  .Dropzone_Preview_container{
    display: flex;
    gap: 7px;
    flex-wrap: wrap;
  }
  .Dropzone_Preview_container{
    display: flex;
    gap: 7px;
    flex-wrap: wrap;
  }
  .product_image_lable{
    font-size: 16px !important;
    font-weight: 400 !important;
    color: #757575;
  }
  .dropzone_container_Product label {
    display: flex !important;
    justify-content: center !important;
    border: none !important;
    align-items: center !important;
    height: 55px;
    border: 1px solid #bcbbbb !important;
    border-radius: 4px !important;
    min-width: 300px !important;
    max-width: 100% !important;
  }
  
  .imageUploadClass{
    top: -16px;  
    width: 100%;  
  }
  .agree_text_document{
    margin: 10px 0px;
  }

  .dish_tag_container div{
  min-height: 45px !important;
  margin-top: 5px !important; 
  }
  .agree_text_document span {
    color: var(--colorused);
    cursor: pointer;
    font-weight: 500;
  }
  .delete_button{
    position: absolute;
    border: none;
    border-radius: 50%;
    padding: 2px;
    height: 25px;
    width: 25px;
    text-align: center;
    background: red;
    color: #fff;
    right: 0px;
    cursor: pointer
  }
  .single_image_contaoner{
    width: 100px ;
  }
  .preview_image{
    position: relative;
  }

  .rejectedText{
    color: red;
    margin: 0px;
    font-size: 14px;
    font-weight: 600;
  }

  .verifiedText{
    color: green;
    margin: 0px;
    font-size: 14px;
    font-weight: 600;
  }
  .fassiEnddate{
        width: 100% !important;
        padding: 8px;
        height: 55px;
        font-size: 16px;
        border: 1px solid #c4c4c4;
        border-radius: 6px;
        color: #373737;
  }


  .image_lable{

  }