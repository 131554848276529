/* index.css */

/* body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  height: 100vh;
} */

.ChatRoom {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 1rem;
  background-color: #f9f9f9;
}

main {
  flex: 1;
  overflow-y: scroll;
  height: 500px;
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
}

.message {
  display: flex;
  margin-bottom: 1rem;
  padding: 0.5rem;
  border-radius: 8px;
}

.sent {
  justify-content: space-between;
}

.received {
  justify-content: flex-start;
}

.message .usermessage,
.message .admin {
  max-width: 60%;
  padding: 0.5rem;
  border-radius: 8px;
  background-color: #e0e0e0;
}

.sent .usermessage {
  background-color: #dcf8c6;
}

.received .admin {
  background-color: #f1f0f0;
}

.usermessage img,
.admin img {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-right: 0.5rem;
}

.admin img {
  margin-left: 0.5rem;
}

/* form {
  display: flex;
  margin-top: 1rem;
} */

input {
  flex: 1;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-right: 0.5rem;
}

button:disabled {
  background-color: #e7e7e7;
  cursor: no-drop !important;
}
