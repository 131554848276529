.outlet_container_registration-form{
    height: 100%;
    width: 80%;
    padding: 20px;
    box-sizing: border-box;
    /* overflow-y: scroll; */
    display: flex;
    justify-content: space-between;
    gap: 10px;
}
.outlet_container_registration_page{
    height: 80vh;
    width: 100%;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    overflow-y: scroll !important;
}



.outlet_container_registration-form::-webkit-scrollbar {
    display: none !important;
  }
  
.steper_countainer_ui{
    background-color: #fff;
    min-width: 100px;
    width: 280px;
    height: 270px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    
}  
.create_ui_steper_heading{
    padding: 15px;
    margin: 0px;
    font-size: 20px;
    font-weight: 400;
    line-height: 20px;

}
.component_countainer_ui{
   width: 70%;
}


.main_ui_contaoiner{
    display: flex;
    flex-direction: column !important;
    align-items: center;
   max-height: 100vh !important;
}

.outlet_btn_container{
    height: 10vh;
    background-color: #fff;
    width: 100%;
    display: flex;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;
}
.outlet_btn_container_inner{
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.nex_btn{
    background-color: var(--colorused);
    color: #fff;
    width: 100px;
    padding: 10px;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    font-size: 18px;
}

.pre_btn{
    background-color: #fff;
    color: var(--colorused);
    width: 100px;
    padding: 10px;
    border-radius: 8px;
    border: 1px solid var(--colorused);
    cursor: pointer;
    font-size: 18px;
}
.header_width{
    width: 100% !important;
}
.text_modal_popup{
    font-size: 18px !important;
    font-weight: 400 !important;
    margin-bottom: 15px;
}

/* ----------------------------------------------------------------------------------------------- */

.line_ui{
    margin: 5px 3px;
    height: 85px;
    background-color: #ababab;
    border:none ;
    width: 3px;
    border-radius: 30px;
}
.steper_content_ui {
    display: flex;
    padding-top: 15px;
}
.step_num{
    width: 20px;
    height: 20px;
    max-width: 20px;
    max-height: 20px;
    margin-top: 5px;
    margin-left: 2px;
}
.stepes_heading_ui_text{
    height: 90px;
    display: flex;
    gap: 5px;
    padding: 2px;
}
.num_text_container{
    display: flex;
}
.step_h{
    margin: 0px;
    font-weight: 400;
    font-size: 16px;
}

.step_p{
    margin: 0px;
    font-size: 13px;
}
.main_heading_ui{
    font-size: 30px;
    margin: 2px 0px 21px 5px;
    font-weight: 400;
}


@media (max-width: 900px) {
    .steper_countainer_ui{
        display: none;
    }
    .component_countainer_ui{
        width: 100%;
    }
      .resturent_container {
        width: 90vw; /* Viewport width ka 90% le */
        max-width: 1200px; /* Maximum width fix karne ke liye */
        float: right !important;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 20px; /* Optional padding for better appearance */
    }
  }

  .okbtn{
    border: none;
    background-color: var(--colorused);
    padding: 7px 30px;
    border-radius: 6px;
    color: white;
  }