.search-feild{
    max-width: 90%;
    height: 35px;
    /* margin-top: 8px;np */
    border: 1px solid #b7b7b7;
    border-radius: 7px;
    font-size: 15px;
    width: 200px;
    background-color: #ffffff;
    padding: 2px 8px 2px 2px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
}
.search-feild input{
    border: none;
    padding: 0px 10px;
    height: 100%;
    max-width: 90%;
}
.table_heading{
    font-size: 22px;
    font-weight: 540;
    margin: 6px;
}
.status_div_change{
    display: flex;
    align-items: center;
    gap: 15px;
}
.status_div_change button{
    padding: 10px;
    color: #ffffff;
    cursor: pointer;
    background-color: var(--colorused);
    border: none;
    outline: none;
    border-radius: 6px;
}
.codeverify_btn{
    width: 100px;
    border-radius: 6px;
    border: none;
    background-color: var(--colorused);
    color: #fff;
    cursor: pointer;
    height: 55px;
    font-size: 18px;
}
.codeverified_btn{
    width: 100px;
    border-radius: 6px;
    border: 1px solid var(--colorused);
    background-color: #ffffff;
    color: var(--colorused);
    cursor: pointer;
    height: 50px;
    font-size: 18px;
}
.order_status_text{
    width: 80px;
}
.span_id{
  font-size: 16px !important;
  color: var(--colorused) !important;
}
.status_p{
  display: flex !important;
  align-items: center !important;
  gap: 10px !important;
  padding: 0px 30px;
}
.status_p p{
  color: var(--colorused) !important;
  cursor: pointer;
  margin: 0px;
}

/* / Order Details / */
.id_navigate{
    cursor: pointer;
    color: blue;
}
.viewsreen_container{
    width: 100%;
    padding: 15px 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: #ffffff;
    border-radius: 10px;
}

.partneredithead {
    cursor: pointer;
  }
  
  input[type='file'] {
    color: transparent !important;
  }
  
  .partner_image_value {
    position: absolute;
    left: 8rem;
    top: 1rem;
    width: 50%;
    white-space: nowrap;
  }
  
  .longtextcss {
    word-break: break-word;
    max-width: 350px;
    white-space: normal;
  }
  
  .viewpagevalues {
    font-size: 16px;
    margin: 0;
    display: flex;
    gap: 5px;
    align-items: center;
  }
  .viewpagevalues button {
    padding: 10px;
    color: #ffffff;
    cursor: pointer;
    background-color: var(--colorused);
    border: none;
    outline: none;
    border-radius: 6px;
  }
  .view_membership_section {
    display: flex;
    gap: 50px;
  }
  .view_membership_field {
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 20px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    align-items: center;
  }
  .view_membership_status {
    display: flex;
    justify-content: center;
  }
  .membership_detail {
    width: 98%;
    margin-left: 13px;
  }
  .detail_head {
    margin-top: 50px;
    margin-left: 13px;
    font-size: 24px;
  }
  .country_limit {
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .view_membership_field img {
    width: 70px;
  }
  .customer_demographic_email {
    display: flex;
    width: 48%;
    justify-content: space-between;
  }
  
  .editButtonclass {
    cursor: pointer;
  }
  
  .view_membership_field b {
    font-size: 16px;
    line-height: 45px;
  }
  
  .containerdivuser .editAndAddHeadClass {
    margin-top: 13px;
  }
  .containerdivuser{
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  
  .partner_client{
    white-space: initial !important;
  }
  .partner_client p{
    max-width: 360px;
    word-break: break-all;
  }

  .modalFooter{
    margin-top: 25px !important;
    padding: 2px !important;
  }

  .main_div_design{
    /* background-color: #e9e9e9; */
    padding: 30px;
    border-radius: 15px;
    /* overflow-y: scroll;
    height: 470px; */
  }
  .page_heading_div{
    background-color: #ffffff;
    border-radius: 10px;
    margin: 0px 0px 10px 0px;
    padding: 5px 10px;
    display: flex;
    flex-wrap: wrap;
  }
