.createsurprise_bag_mainContainer{
    height: 100vh;
    display: flex;
    gap: 2px;
    padding: 20px;
    box-sizing: border-box;
}
.end-date_lable{
    display: flex;
    flex-direction: column;

}

.end-date_lable label{
    font-size: 18px;
}

.surprise_bag_outlet_container{
    width: 45%;
    padding: 20px 0px;
    min-width: 400px;
}


.bag_size_label {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

@media screen and (max-width: 900px) {
    .surprise_bag_outlet_container {
        width: 100%;
        padding: 0px;
        padding-right: 15px;
    }
    .main_registration_image_section{
        display: none;
    }
  }


.welcome_screen_btn{
    padding: 12px;
    color: #ffffff;
    background-color: #ffffff;
    border: none;
    cursor: pointer;
    margin: 5px 10px 15px 10px;
    color: #315257;
    border-radius: 25px;
    font-weight: 550;
}

.container_btn_img{
    background-color: var(--colorused);
    color: #ffffff !important;
    /* width: 320px; */
    
    object-fit: contain;
    border-radius: 10px;
}
.container_btn_img img{
  
   width: 100%;
    border-radius: 10px 10px 0px 0px;
}
.container_btn_img p{
    padding:10px;
    margin: 2px;
    font-weight: 500;
    color: var(--colorWhite);
}
.create_bag_main_screen_container{
    padding: 0px 10px;
    min-width: 350px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}




.MuiAutocomplete-clearIndicator{
    display: none !important;
}
.descriptionlimit{
    font-size: 16px;
    margin: 0px;
    text-align: end;
    color: #acacac;
    font-weight: 400;
}
.lable_name{
    margin-bottom: 2px;
}

/* ------------------------------------------------------------------------------------------------------------------ */


.dropdown-toggle {
    /* / white-space: nowrap !important';  / */
    color: black !important;
    background-color: white !important;
    border: none !important;
    width: 20px !important;
}
.line{
    margin: 5px 3px;
    /* / color: #0c0b0b; / */
    height: 4px;
    background-color: #ababab;
    border:none ;
    /* / border: 1px soild; / */
    /* / border: 1px solid; / */
    width: 25%;
    border-radius: 30px;
}
.line:hover{
    background-color: rgb(1, 80, 1);
}
.bttn{
    border: 1px solid #b8dbb3;
    padding: 8px 30px;
    margin: 5px;
    cursor: pointer;
    background: none;
    border-radius: 6px;
}
.clockdiv{
    border: 1px solid #d7d7d7;
     padding:  3px 10px;
}
.form-check-input[type=radio] {
    border-radius: 50%;
    border: 1px solid #5198cb !important;
}
@media screen and (max-width: 658px) and (min-width: 320px) {
.cardcss{
    padding: 5px !important;
}
}
small{
    font-size: 16px;
    font-weight: 400;
    color: grey;
}


.bag_step_heading{
    font-size: 22px;
    margin-bottom: 5px;
    display: flex;
    gap: 2px;
}

.formcontrol_radio{
    border: 1px solid rgb(176 173 173);
    width: 155%;
    margin: 10px 0px;
    color: grey;
    padding: 5px;
    border-radius: 6px;
}

.textarea_field{
    width: 96%;
    border-radius: 6px;
    padding: 10px;
    font-size: 14px;
    font-family: inherit;
    outline: none;
}

.size_container{
    display: flex !important;
    justify-content: space-between !important;
    padding: 10px !important;
    border: 1px solid gray !important;
    border-radius: 6px !important;
    margin: 10px 0px;
    position: relative;
}
.size_container label{
    width: 100%;
}
.size_container label span:nth-child(2){
    width: 100% !important;
}
.margin-0{
    margin: 0px;
}

.name_description_container{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.btn_num_active{
    background-color: var(--colorused);
    color: #fff;
}

.continue_btn{
    float: right;
    border: none;
    padding: 8px;
    width: 100px;
    border-radius: 15px;
    cursor: pointer;
    margin-top: 5px;
}

.democontainer{
    margin-bottom: 10px;
}

.text-align-end{
    text-align: end;
}
.bagPrice{
    font-weight: 500;
    display: flex;
    justify-content: end;
    align-items: center;
    font-size: 18px !important;
}
.align{
    display: flex;
    justify-content: end;
    align-items: center;
    font-size: 18px !important;
}
.bigsize:hover{
background-color:rgb(229, 235, 229);
}
.m-t-0{
    margin-top: 0;
}

.btnactive_continue{
    background-color: var(--colorused);
    color: #ffffff;
}
.medium_recommended_value{
    position: absolute;
    left: 15px;
    top: -12px;
    background-color: #e1eddf;
    padding: 5px 15px;
    border-radius: 16px;
    border: 1px solid #008000;
    color: var(--colorused);
    font-size: 12px;
    text-align: center;
    margin: 0px;
}

.bag_size_lable{
    display: flex;
    justify-content: space-between;
    width: 100% !important;
    align-items: center !important;
}
.datepicker_field_bag div input{
    padding: 8px 10px !important;
}
.datepicker_field_bag{
    width: 80% !important;
    min-width: 230px !important;
}