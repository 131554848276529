.main_home_divider{
    display: flex;
    gap: 2px;
    /* height: 90vh; */
}

.main_home_divider ::-webkit-scrollbar {
    display: none !important;
  }
 


.main_registration_image_section{
    width: 50%;
    padding: 20px;
}

.main_registration_image_section img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
