.container_main_layout{
    box-sizing: border-box !important;
    width: 100% !important;
    max-width: 100% !important;
    padding: 20px;
    min-width: 400px;
    width: 60% !important;
    overflow-y: scroll;
}
.stepcontent_container{
    display: flex;
    /* width: 90%; */
    flex-direction: column;
    gap: 25px;
    /* align-items: end; */
}
.main_layout_insider{
    border: 1px solid #f7f7f7;
    border-radius: 6px;
    padding: 15px;
    box-sizing: border-box;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}
.restaurant_imnformation_text{
    font-weight: 500;
    font-size: 26px;
    padding: 0px 15px;
}