
.schedule_lable{
    width: 120px;
    margin: 0px;
    font-size: 17px;
}
.shedule_grid{
    padding: 20px 10px;
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: space-between;
    align-items: center;
}
.schedule_limit_container{
    display: flex;
    flex-direction: column;
}
.schedule_limit_container input{
    border: 1px solid gray;
    height: 50px;
    width: 60px;
    border-radius: 6px;
    text-align: center;
}
.g-5{
    gap: 10px;
}
.update_chedule_btn{
    border-radius: 23px;
    border: none;
    background-color: var(--colorused);
    color: white;
    padding: 10px 15px;
    cursor: pointer;
    width: 100%;
    font-size: 18px;
}
.form_control_lable{
    display: flex;
    flex-direction: column;
}
.pick_up{
    margin: 0px;
    font-family: Tenon, "Open Sans", Roboto, Arial, sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0.005em;
    text-align: center;
    color: var(--colorused);
    white-space: initial;
    overflow: initial;
    text-overflow: initial;
    text-transform: initial;
}
.no_pick_up{
    margin: 0px;
    font-family: Tenon, "Open Sans", Roboto, Arial, sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0.005em;
    text-align: center;
    color: rgb(183, 57, 73);
    white-space: initial;
    overflow: initial;
    text-overflow: initial;
    text-transform: initial;
}