.faq_wrapper_container_fluid{
    padding: 10px;
    display: flex;
    width: 88%;
}
.faq_container_first_block{
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.faq_container_second_block{
    padding: 15px 0px;
    min-width: 300px;
    width: 100%;
}
@media (max-width: 570px) {
    .faq_wrapper_container_fluid{
        display: block;
    }
    .faq_container_first_block{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .faq_container_second_block{
        width: 100%;
        padding: 15px 0px;
    }
}
