.dropzone_container_category label{
    margin-top: 5px;
    border: 1px solid gray !important;
    min-width: 300px !important;
    max-width: 100% !important;
}
.dropzone_container_category {
    display: flex;
    gap: 10px;
    flex-direction: column;
    width: 100%;
}
.imageupload_btn{
    width: 100px;
    padding: 10px;
    border-radius: 6px;
    border: none;
    background-color: var(--colorused);
    color: #fff;
    cursor: pointer;
}
.image_lable{
    border: none !important;
    font-weight: 500;
    margin: 2px;
}
.preview_image{
    margin-top: 5px;
}
.preview_image img{
   border-radius: 6px;
}
/* Replace `.file-uploader-success-message` with the actual class or element that contains "Upload another" */
.file-uploader-success-message {
    display: none; /* Hides the "Upload another" text */
  }
  