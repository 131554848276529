/* index.css */

.chat-body {
    position: relative;
   
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .chatBtn {
    position: fixed;
    bottom: 10px;
    right: 10px;
    
  }
  strong{
    white-space: nowrap;
  }
   
  .chat-modal {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
  }
  
  .chat-content {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  
  .chat-room {
    flex: 1;
    overflow-y: auto;
  }
  
  .modal-box {
    width: 80vw;
    max-width: 800px;
    height: 80vh;
    max-height: 90vh;
    border-radius: 8px;
  }
  /* ********************deatil ************ */

  .ticket-detail-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .chatBtn {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #ffffff;
    border-radius: 50%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s, transform 0.3s;
    cursor: pointer;
  }
  
  .chatBtn:hover {
    background-color: #f1f1f1;
    transform: scale(1.1);
  }
  
  .chat-room {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 95%;
  }
  /* ********************************************** */

  .ticket-details-container {
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding: 1rem;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .ticket-details-box {
    padding: 1.5rem;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border: 1px solid #ddd;
  }
  
  .ticket-details-title {
    font-weight: bold;
    font-size: 28px;
    margin-bottom: 1rem;
    color: #333;
  }
  
  .ticket-details-item {
    margin-bottom: 0.5rem;
  }
  

















  .popup-message {
    position: fixed;
    bottom: 75px; /* Positioning from the bottom of the screen */
    right: 50px; /* Positioning from the right of the screen */
    background-color: var(--colorused);
    padding: 10px 20px;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    font-size: 16px;
    color: white;
    animation: blink 1s step-end infinite;
    text-align: center; /* Center text */
    z-index: 10; /* To make sure it's visible on top */
  }
  
  .popup-message::after {
    content: "";
    position: absolute;
    bottom: -10px; /* Positioning arrow below the popup */
    left: 84%;
    top: 43px;
    transform: translateX(-50%);
    border-width: 10px;
    border-style: solid;
    border-color: var(--colorused) transparent transparent transparent;
  }
  
  @keyframes blink {
    50% {
      opacity: 0;
    }
  }
  