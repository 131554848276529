.searchbox {
    width: 270px;
    height: 40px !important;
    border: 1px solid transparent; /* Set an initial border */
    border-radius: 9px;
    transition: border-color 0.3s; /* Smooth transition */
}

.searchbox:focus {
    border-color: rgb(67, 24, 255) ;
}
.graphs1{
    background-color: #ffffff;
    border-radius: 10px;
    height: 15vh;
    color: gray;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    height: auto;

    /* margin: 1px; */
}
.balancegraph{
    position: relative;
    width: 98% !important;
    height: 215px !important;
    padding: 0px;
    margin: 0px;
    border-width: 0px;
    cursor: default;
    max-height: 250px;
}

.balancegraph1{
    position: relative;
    /* width: 45vw !important; */
    height: 250px !important;
    padding: 0px;
    margin: 0px;
    border-width: 0px;
    cursor: default;
}





.periodDivClass p{
    margin-left: 10px;
    margin-bottom: -6px;
}

  .periodDivClassDropdown .MuiFormControl-root  .MuiInputBase-root .MuiSelect-select {
    padding: 8px;
  }
  
.typeAndPeriodClass{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 5px 20px 5px;
    flex-wrap: wrap;
    width: 100% !important;
    /* padding: 0px 20px; */
}
.periodDivClass{
  /* margin: 5px -12px ; */
}
/* .customdate_perioddiv{
  margin: 0px 0px !important;
} */
.pie_card{
  height: 400px !important;
}

.bigger_component{
  width: 68%;
  min-width: 300px;
}

.smaller_component{
  width: 30%;
  min-width: 300px;
}
 .chart-pie-container {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  flex-wrap: wrap; 
}
.heightcard{
  height: 380px !important;
}

.dhasboard_date_container{
  padding: 5px;
  height: 40px;
  border-radius: 4px;
  outline: blue;
  border: 1px solid #bababa;
  /* margin: 15px; */
}
.dhasboard_date_container_main{
  display: flex;
  gap: 5px;
  margin-bottom: 8px;
  margin-left: 10px;
}
.date_picker_label_dashboard{
  margin: 0 !important;
}

.typeAndPeriodClassContainer{
  display: flex;
  gap: 5px;
  align-items: center;
  flex-wrap: wrap;
}

.periodDivClassPeriodtext {
  margin: 5px -12px !important;
}

@media screen and (max-width: 820px) {
  .hidenotification {
    display: none !important;
  }
}
.hidenotification {
  padding: 5px 20px;
  height: 50px;
  /* display: flex;
  justify-content: space-between;
  align-items: center; */
}
.logo_text_img_not{
  /* width: 90%; */
}

@media (min-width: 320px) and (max-width: 700px){
  .media_query_create{
    text-align: left !important;
  }
}