.formcontrol-div label{
    min-width: 200px;
    width: 25%;
}
.operation_field_container{
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
}
.operation_field_container label{
    width: 125px;
}
.time_container{
    display: flex;
    flex-direction: column;
}

.time_container input{
    border-radius: 6px;
    padding: 6px;
    /* border: 1px solid gray; */
    outline: none;
}

.establishmenttype_pera{
    margin: 0px;
    color: #a9a3a3;
    font-size: 16px;
}

.cusinscontainer{
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 14px;
    background-color: #d5edf9;
    line-height: 2px;
    padding: 0px 5px;
    border-radius: 6px;
    font-weight: 500;

}
.cuising_display{
    display: flex;
    align-items: center;
    gap: 8px;
    flex-wrap: wrap;
}
.selected_value_container{
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 12px;
}
.acordian_heading_text{
    font-size: 22px;
    margin: 2px;
    font-weight: 500;
}
.acordian_heading_container p{
    font-size: 14px;
    margin: 2px;
}
.establisment_heading_text{
    margin: 0;
    font-weight: 300;
}
.radio_container{
    display: flex !important;
    flex-direction: column !important;
    gap: 25px;
}

.radio_container label{
    display: flex !important;
    gap: 2px;
    align-items: flex-start;
}
.establisment_heading_container{
    margin-top: 5px;
}
.timePicker_wrap{
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    gap: 15px;
}






.date-picker-container {
  display: flex;
  flex-direction: column;
 
  gap: 20px;
  

  border-radius: 10px;

}

.day-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
flex-wrap: wrap;
  max-width: 100%;
  padding: 10px;
  /* background-color: #ffffff;
  border: 1px solid #ddd; */
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.day-item label {
  flex-grow: 1;
  margin-left: 10px;
  font-size: 16px;
  color: #333;
}

.day-item input[type="checkbox"] {
  margin-right: 15px;
}

.MuiFormControl-root {
  margin-left: 15px;
  margin-right: 15px;
  flex-grow: 1;
}

  