/* index.css */

/* Styling for the heading */
.heading {
  
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 700;
   padding-top: 20px;
    font-size: 23px;
    line-height: 1.6;
    letter-spacing: 0.0075em;
   
  }
  

  .centerpart{
    height: 280px !important;

    overflow-y: scroll;
  }
  /* Styling for the image */
  .logo_image {
    width: 100px;
    height: 100px; 
    margin-top: 30px !important; 
    margin-bottom: 10px !important;
    border-radius: 50%;
    display: block;
    margin: auto;
    object-fit: cover;
  }
  .cover_image_logo{
    width: 100%;
    height: 184px;
    margin-top: 30px !important;
    margin-bottom: 10px !important;
    /* border-radius: 50%; */
    display: block;
    margin: auto;
    object-fit: cover;
  }
  
  /* Styling for the Browse button */
  .Browser_btn {
    color: green;
    padding: 10px 20px;
    border: 2px solid green;
    font-size: 16px;
    border-radius: 24px;
    font-weight: 500;
    cursor: pointer;
     font-family: Tenon, "Open Sans", Roboto, Arial, sans-serif;
    font-size: 16px;
    line-height: 24px;
    width: 100px;
    display: block;
    margin: auto;
    text-align: center;
  }
  
  /* Styling for the uploading message */
  .uploading_msg {
    background:  rgb(211, 210, 210);
    padding: 10px;
    width: 100%;
    font-size: small;
    margin-top: 10px;
    text-align: left;
  }
  
  /* Styling for the divider */
  .divider {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
    border: 1px solid gray;
  }
  
  /* Styling for the last buttons (Cancel and Apply) */
  .lastbtn {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }
  
  .lastbtn .cancel,
  .lastbtn .Apply {
    padding: 10px 20px;
    cursor: pointer;
    border: 1px solid transparent;
    border-radius: 24px;
    font-size: 16px;
    font-weight: 500;
  }
  
  .lastbtn .cancel {
    color: rgb(170, 169, 169);
  }
  
  .Apply {
    background-color: var(--colorused);
    color: white;
  }
  
  .lastbtn .Apply.disabled {
    opacity: 0.5;
    cursor: not-allowed;
    background-color: aliceblue;
    color: rgb(170, 169, 169);
  }
  
  /* Responsive adjustments (optional) */
  @media (max-width: 768px) {
    .Browser_btn {
      font-size: 14px;
    }
  
    .lastbtn .cancel,
    .lastbtn .Apply {
      padding: 8px 16px;
      font-size: 14px;
    }
  }
  