.create_ticket_container{
    padding: 30px;
    display: flex;
    flex-direction: column;
    gap: 25px;
}
.align-end{
    text-align: end;
}
.add_support_heading{
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 0px 30px;
}
.send_us_text{
    margin: 0px;
    color: gray;
    font-weight: 600;
}
.expert_text{
    margin: 0px;
    color: gray;
    font-weight: 400;
}
.textarea_help{
    width: 100%;
    border-radius: 6px;
    padding: 10px;
    font-size: 14px;
    font-family: inherit;
    outline: none;
}
.support_ticket{
    background-color: var(--colorused);
    color: #fff;
    padding: 7px 10px;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    font-size: 18px;
}
.search_align_container{
    display: flex;
    align-items: center;
    gap: 10px;
}
.id_navigate{
    cursor: pointer;
    color: blue;
    text-decoration: underline;
}
.alert_message{
    height: 80vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.alert_message h1{
    text-align: center;
    font-size: 26px;
    font-weight: 500;
}
.m-t-15{
    margin-top: 15px;
}
.support_create_container{
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
}