.dashboard_surprise_bagcontainer {
    width: 22%;
    min-width: 300px;
    height: 250px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border-radius: 25px;
    cursor: pointer;
}

/* .dashboard_surprise_bagcontainer img {
    border-radius: 6px 6px 0px 0px;
    width: 100%;
    height: 45%;
} */
.surprise_bag_image_dashboard_container{
    border-radius: 20px 20px 0px 0px;
    width: 100%;
    height: 100%;
    background-size: cover;
   
}
.surprise_bag_image_dashboard_container_main{
    border-radius: 20px 20px 0px 0px;
    width: 100%;
    height: 45%;
    position: relative;
}
.selling_btn{
    position: absolute;
    margin: 0px;
    top: 10px;
    left: 15px;
    padding: 0px 9px 0px 0px;
    border-radius: 24px;
    background: rgb(212, 246, 213);
    color: rgb(19, 102, 87);
    z-index: 1;
    font-size: 13px;
    font-weight: 700;
}

.dashboard_home_main_container {
    border: 1px solid gray;
    border-radius: 10px;
    background-color: #ffffff;

}

.surprice_bag_name_dashboard_container {
    /* text-align: center; */
    height: 30%;
    padding: 10px;
}
.addNew_btn{
    width: 100px;
    padding: 10px;
    border-radius: 25px;
    border: none;
    background-color: var(--colorused);
    color: #fff;
    cursor: pointer;
}


.surprice_bag_name_dashboard {
    font-weight: 600;
    margin: 0px;
    padding: 2px 5px;
}

.surprice_bag_category_dashboard {
    font-weight: 500;
    margin: 0px;
    padding: 2px 5px;
    line-height: 0px;
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 5px;

}

.surprise_bag_list_container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 20px;
}

.surprisebag_list_heading {
    padding: 15px 0px 0px 15px;
}

.dashboard_activate_btn_modal {
    background-color: var(--colorused);
    color: #fff;
    border: none;
    padding: 10px;
    white-space: nowrap;
    border-radius: 20px;
    cursor: pointer;
    font-size: 15px;
    width: 93%;
    margin: 8px 5px 10px 5px;
}
.backgroundgray{
    background-color: #e6e5e5;
    cursor: no-drop !important;
}
/* ------------------------------------------------------------------------------------------------------------- */

.sur_bag_modal_container div {
    padding: 5px;
    border: none !important;
    outline: none !important;
    border-radius: 12px;

}
.restaurant_heading_main_greet{
    padding: 2px 10px;
    font-size: 23px;
    width: 45%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

}

.modal_upper_part {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.modal_text {
    /* margin: 0px; */
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
}

.editSurprise_bag_container {
    border-radius: 6px;
    border: 1px solid #dee3e3;
    margin-top: 20px;

}

.button_inc_dec {
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    outline: 0px;
    border: 0px;
    margin: 0px;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    font-family: Tenon, "Open Sans", Roboto, Arial, sans-serif;
    font-weight: 500;
    font-size: 28px;
    line-height: 24px;
    letter-spacing: 0.005em;
    text-transform: uppercase;
    min-height: 36px;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 50%;
    padding: 0px;
    min-width: 0px;
    width: 40px;
    height: 40px;
    color: rgb(255, 255, 255);
    background-color: var(--colorused);
    flex: 0 0 auto;
    z-index: inherit;
    box-shadow: none;
}

.edit_bag_heading {
    margin: 0px 0px 16px;
    font-family: Tenon, "Open Sans", Roboto, Arial, sans-serif;
    font-weight: 700;
    letter-spacing: 0.01em;
    font-size: 20.3px;
    line-height: 24px;
    white-space: initial;
    overflow: initial;
    text-overflow: initial;
    text-transform: initial;
}

.container_fluid_button_edit {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    gap: 16px;
}

.editSurprise_bag_container_inside {
    padding: 25px;
}

.edit_meal_heading_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 0px 20px 0px;
}

.edit_meal_heading {
    margin: 0px;
    font-family: Tenon, "Open Sans", Roboto, Arial, sans-serif;
    font-weight: 700;
    letter-spacing: 0.005em;
    font-size: 23px;
    line-height: 24px;
    white-space: initial;
    overflow: initial;
    text-overflow: initial;
    text-transform: initial;
}

.radio_button_align {
    display: flex !important;
    align-items: center !important;
    flex-direction: row !important;
    gap: 25px;
}

.day_select {
    margin-top: 25px;
    color: #000000 !important;
    font-weight: 600 !important;
}

.editBag_time_field_input {
    max-width: 80% !important;
    width: 240px !important;
    min-width: 150px !important;
}
.endError {
    max-width: 80% !important;
    width: 240px !important;
    min-width: 150px !important;
    color: red;
}
.radio_button_align label span{
    color: var(--colorused);
}
.container_fluid_button_edit div div input{
    text-align: center !important;
    min-width: 15px;
}
.stock_input_box {
    width: 1px;
    max-width: 50px !important;
    text-align: center;
    min-width: 50px !important;
}
.AddNew_container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 15px;
    padding-top: 5px !important;
}
.message_container{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 80vh;
}
.message_container_fluid{
    padding: 20px;
    border: 1px solid gray;
    border-radius: 6px;
}

/* ---------------------------reservation table------------------------------------------ */


/* ReservationsTable.css */
.reservation-table-container {
    padding: 20px;
    margin-top: 40px;
    box-shadow: none !important;
    border: 1px solid gray;
    border-radius: 15px !important;
  }
  
  .reservation-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .reservation-header-title {
    font-weight: bold;
  }
  
  .reservation-select {
    margin-bottom: 20px;
  }
  
  .table-header-cell {
    font-weight: bold;
  }
  
  .status-canceled {
    color: red;
  }
  
  .status-picked-up {
    color: green;
  }
  
  .status-default {
    color: black;
  }
  
  .id-cell {
    background-color: #f0f0f0;
    padding: 5px 10px;
    border-radius: 5px;
    display: inline-block;
    cursor: pointer;
  }
  

  .timeMagBag_detail{
    margin-top: 5px;
    display: flex;
    /* justify-content: flex-start; */
    align-items: center;
  }
  .add_special_afteractivation{
    padding: 15px 15px 0px 15px;
  }
  .scrollable-hidden {
    overflow-y: scroll;
    height: 70vh;
    scrollbar-width: none; /* For Firefox */
  }
  
  .scrollable-hidden::-webkit-scrollbar {
    display: none; /* For Chrome, Safari, and Edge */
  }
  