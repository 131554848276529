.resturent_container{
    width: 100% !important;
    float: right !important;
    display: flex;
    justify-content: end;
    flex-direction: column;
    /* gap: 20px; */
    padding-bottom: 30px;
}
.accordion_gap{
    display: flex;
    justify-content: end;
    flex-direction: column;
    gap: 20px;
    padding: 15px !important; 
}
.accordion_gap1{
    display: flex;
    justify-content: end;
    flex-direction: column;
    /* gap: 20px;
    padding: 15px !important;  */
}

.mobile_phone_field div{
    padding: 6px !important;
    outline: none !important;
    border: none !important;
}

.mobile .mobile_phone_field{
    border: 1px solid #a1a1a1 !important;
    width: 100%;
    border-radius: 4px;
    
}
.mobile_container{
    display: flex;
    justify-content: space-between;
}
.mobile_phone_field .flag-select__btn {
    display: none !important;
}
.mobile_phone_field .MuiPhoneNumber-flag {
    display: none !important;  /* Assuming .MuiPhoneNumber-flag is the class of the flag container */
}
.mobile_phone_field .flag-select__btn {
    display: none !important;  /* Hides the flag select button */
}
.mobile_phone_field .MuiPhoneNumber-country {
    display: none !important;  /* Hides the country container */
}
.mobile_container button{
    width: 15%;
    border-radius: 6px;
    cursor: pointer;
    min-width: 70px;
    margin-left: 4px;
    height: 50px;
}
.mobile{
  width: 80%;
}
.countrycode_input div{
    border-right: none !important;
}
.form-error{
    color: red;
    margin: 2px;
}
.verified_btn{
    border: 1px solid green;
    color: green;
}
.verify_btn{
    background-color: var(--colorused);
    border: none;
    color: #fff;
}
.resturant_acordian{
    min-width: 330px !important;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px !important;
    border-radius: 10px !important;
    /* padding: 20px; */
    box-sizing: border-box !important;
}
.cursornodrop{
    cursor: no-drop !important;
}
.resturant_sendinfo_text{
    align-items: center;
}
.address_searchbox{
    width: 100%;
    height: 50px;
    padding: 0px 10px;
    border-radius: 6px;
    margin-bottom: 10px;
    outline: none;
    border: 1px solid #c4c4c4;
}
.mapContainer{
    padding: 0px !important;
}
.otp_input_container .otp_input_type{
   max-width: 40px !important;
}