.calender_btn_modal{
    background-color: var(--colorused);
    color: #fff;
    border: none;
    padding: 10px;
    width: 130px;
    white-space: nowrap;
    border-radius: 20px;
    cursor: pointer;
    margin-top: 5px;
    font-size: 15px;
}    
.labell{
    color: var(--colorused)   ;
}
.display_align{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
}
.input_time_field{
    border-radius: 6px;
    padding: 6px;
    border: 1px solid gray;
    outline: none;
    width: 150px !important;
    height: 55px;
}
.m-t-10{
    margin-top: 20px;
}
.calender_modal_value{
    margin: 2px;
    font-size: 16px;
    font-weight: 500;
    color: #9d9d9d;
}
.input_time_field_input{
    max-width: 150px !important;
    width: 150px !important;
    min-width: 150px !important;
}
.date_input_calender{
    max-width: 150px !important;
    width: 150px !important;
    min-width: 150px !important;
    padding: 8px;
    height: 50px;
    font-size: 16px;
    border: 1px solid #c4c4c4;
    border-radius: 6px;
    color: #373737;
}
.flex-wrap{
    flex-wrap: wrap !important;
}
.add_special_heading{
    margin: 0px !important;
    font-family: Tenon, "Open Sans", Roboto, Arial, sans-serif !important;
    font-weight: 700 !important;
    letter-spacing: 0.005em !important;
    font-size: 23px !important;
    line-height: 24px !important;
    text-transform: none !important;
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
}
