/* .login-container{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
} */

.login-container {
  position: relative; /* Ensure the element is positioned relative to the pseudo-element */
  background-image: url("../../images/bgimage.png");
  background-size: cover;
  background-position: center;
  object-fit: contain;
  height: 100vh;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-container::before {
  content: ""; /* Required to create the overlay */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Overlay color with 50% opacity */
  z-index: 1; /* Ensure it is on top of the background image */
}
.login_logo_image{
  height: 50px;
  margin: 5px 0px;
}
.login_logo_image1{
  height: 50px;
  object-fit: contain !important;
}
.creatperatext , p{
  color: #000000;
}

.login-container_fluid {
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 25px 25px 30px 25px;
  width: 30%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  min-width: 320px;
  border-radius: 6px;
  position: relative; /* Ensure the content is above the overlay */
  z-index: 2; /* Ensure the content is above the overlay */
  color: white;
  background-color: #ffffff;
}
.login-div {
  display: flex;
  align-items: space-between;
  flex-direction: column;
}

.createaccount_btn {
  border: none;
  cursor: pointer;
  text-align: left;
  padding: 1px;
  color: var(--colorused);
  font-size: 18px;
  background: none !important;
}

.mobile_phone_field div {
  padding: 6px !important;
  outline: none !important;
  border: none !important;
}

.login-container_fluid .mobile_phone_field {
  border: 1px solid #a1a1a1 !important;
  width: 100%;
  border-radius: 4px;
}
.login-btn {
  width: 100%;
  padding: 15px;
  background-color: var(--colorused);
  color: #fff;
  border: none;
  border-radius: 6px;
  font-size: 18px;
  cursor: pointer;
}
.class-or {
  text-align: center;
  margin: 0;
}
.social-media-btn {
  border: 1px solid #a1a1a1 !important;
  padding: 10px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2px;
}

/* =========================================otp style===================================== */

/* otp message enter the otp you received on your mobile */
.otpmsg {
  margin: 20px;
  text-align: center;
  color: grey;
  font-size: 24px;
  font-weight: 400;
}
.login-div h3 {
  font-size: 26px;
  color: #4b4646;
  font-weight: 500;
}

.creditcard {
  margin: auto;
  background-color: white;
  margin-top: 5%;
}

.otpverify_resend {
  text-align: center;
  font-size: 26px;
}
.otpverify_resend_btn {
  text-align: center;
  font-size: 24px;
  border: none;
  color: grey;
  background-color: #fff;
}

.box {
  text-align: center;
  outline: none;
  margin: 3px;
  padding: 5px;
  width: 35px;
  height: 35px;
  font-size: 20px;
  border: 1px solid grey;
  border-radius: 6px;
}
.otpbox_container {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
}

.signupcontainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.input_sign_password {
  height: 55px !important;
}
.form-error {
  color: red;
}
.checkbox_agreetext_container {
  align-items: flex-start;
  display: flex;
}
.checkbox_agreetext_container label {
  margin: 0px;
}
.agree_text {
  margin: 0px;
  margin-top: 10px;
  /* display: flex; */
  /* justify-content: center; */
  align-items: center;
}
.agree_text span {
  color: var(--colorused);
    cursor: pointer;
    font-weight: 500;
}
.Forget_password_text {
  text-align: end;
  margin: 0px;
  cursor: pointer;
  color: var(--colorused);
  font-size: 18px;
}
.Forget_password_lable {
  margin-bottom: 15px !important;
  margin: 0px;
  font-size: 16px;
}
.otp_input_type {
  text-align: center;
  outline: none;
  margin: 3px;
  padding: 5px;
  width: 30px;
  height: 40px;
  font-size: 20px;
  border: 1px solid grey;
  border-radius: 6px;
}
